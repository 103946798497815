.action-button {
  /* override default Actionbutton styling */
  position: relative;
  top: 6px;
  margin-left: 8px;
  text-align: center;
  display: inline-block;
}

.main-container {
  display: grid;
  padding-left: 20px;
  grid-template-columns: 2fr 5fr 2fr 1fr;
  grid-template-rows: calc(45vh - 4em) 55vh;
  background-color: var(--component-bg-main);
  grid-template-areas:
    "imagecontainer detailcontainer mapcontainer backbutton "
    "comments comments comments comments";
  font-weight: 100;
}

.image-container {
  grid-area: imagecontainer;
  align-self: center;
  padding: 10px;
  height: 100%;
}

.image {
  object-fit: fill;
  width: 100%;
  height: 100%;
}

.detail-container {
  grid-area: detailcontainer;
  padding: 10px;
  max-height: 100%;
  overflow: auto;
}

.detail-content {
  font-size: 1.2em;
  font-weight: 100;
  margin-top: 7px;
}

.map-container {
  grid-area: mapcontainer;
  display: grid;
  grid-template-rows: 9fr 1fr;
  padding: 0 10px;
}

.map-text {
  align-items: flex-end;
  text-align: center;
  font-size: 0.8em;
  font-weight: 100;
  padding-top: 7px;
}

.button-container {
  grid-area: backbutton;
  justify-self: end;
}

.comment-area-container {
  grid-area: comments;
}

.inline-block {
  display: inline-block;
}

@media screen and (max-width: 550px) {
  .main-container {
    padding: 0;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 7vh calc(40vh - 4em) 33vh 20vh;
    background-color: var(--component-bg-main);
    grid-template-areas:
      "backbutton backbutton"
      "imagecontainer mapcontainer"
      "detailcontainer detailcontainer"
      "comments comments";
  }

  .image-container {
    grid-area: imagecontainer;
    padding: 0;
    align-self: start;
    max-height: 180px;
  }

  .image {
    height: 180px;
  }

  .likes-container {
    float: right;
  }

  .detail-content {
    font-size: 0.7em;
    margin-top: 7px;
  }

  .map-text {
    font-size: 0.6em;
  }

  .action-button {
    margin: 0 2px;
  }
}
