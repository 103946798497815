.main-page-container {
  display: grid;
  grid-template-columns: 3fr 2fr;
  grid-template-rows: calc(100vh - 4em);
  background-color: var(--component-bg);
}

@media screen and (max-width: 780px) {
  .main-page-container {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: calc(55vh - 4em - 5px) 45vh;
    grid-gap: 5px;
    background-color: var(--component-bg);
  }
}
