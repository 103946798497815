/* reset default button styling */
input[type="submit"] {
  box-shadow: 0px 0px 0px transparent;
  border: 0px solid transparent;
  text-shadow: 0px 0px 0px transparent;
}

input[type="submit"]:hover {
  box-shadow: 0px 0px 0px transparent;
  border: 0px solid transparent;
  text-shadow: 0px 0px 0px transparent;
}
/* end of reset default button styling */

/* custom button styling */
input[type="submit"] {
  margin: 5px 0 0 0;
  padding: 8px;
  border: 1px solid darkgrey;
  width: 100%;
}

input[type="submit"]:hover {
  border: 1px solid black;
}
input[type="submit"]:active {
  border: 2px solid black;
}

/* component styling */
.main-comments-container {
  display: grid;
  grid-template-columns: 7fr 3fr;
  height: 100%;
  grid-template-areas: "comments comment-input";
}

.comment-container {
  grid-area: comments;
  overflow-y: scroll;
  padding-bottom: 10px;
}

.comment-title {
  margin-bottom: 15px;
}

.comment {
  margin: 10px 5px 10px 0;
  line-height: 1.7em;
  padding-right: 10px;
}

.date {
  margin: 10px 0;
  font-size: 0.85em;
  padding-right: 10px;
  text-align: right;
}

.comment-input-container {
  grid-area: comment-input;
  padding: 0 10px 10px 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

form {
  height: 100%;
}

textarea {
  width: 100%;
  height: 91%;
}

@media screen and (max-width: 550px) {
  textarea {
    width: 100%;
    height: 71%;
  }

  .comment-container {
    padding-left: 5px;
  }
}
