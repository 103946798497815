/* this file only exists to dynamically add a line-through to the appropriate data source*/
.line-through {
  text-decoration: line-through;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  height: 4em;
  background-color: var(--component-bg-secondary);
  color: white;
  cursor: default;
}

.icon {
  position: relative;
  bottom: 2px;
  left: 5px;
}

.data-origin-label {
  font-size: 0.5em;
  margin-left: 15px;
  position: relative;
  bottom: 5px;
  font-weight: 400;
}

@media screen and (max-width: 470px) {
  .data-origin-label {
    display: none;
  }
}

@media screen and (max-width: 300px) {
  .header {
    font-size: 5vw;
  }
}
