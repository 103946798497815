:root {
  --card-width: 213px;
  --button-width: 213px;
}

body {
  font-family: "Montserrat", "Lucida Grande", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
}

.card {
  max-width: var(--card-width);
  height: 320px;
  border: 1px solid rgba(169, 169, 169, 0.7);
  margin: 15px 5px;
  border-radius: 5px;
  background-color: var(--component-element-bg);
  position: relative;
  cursor: pointer;
}

.card:hover {
  border: 1px solid black;
}
h3,
h4,
h4,
h5 {
  font-weight: 100;
  margin: 3px 0 0 0;
  text-overflow: ellipsis;
  overflow: hidden;
}

h5 {
  margin: 3px 0 13px 0;
  color: grey;
}

.card img {
  width: calc(var(--card-width) - 2px);
  height: 100px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.card-titles {
  margin: 15px 0;
  padding: 0 10px;
}

.card-content {
  position: absolute;
  bottom: 50px;
  display: inline-flex;
  justify-content: space-around;
  width: 100%;
}

.card-button {
  position: absolute;
  bottom: 0px;
  width: var(--button-width);
  height: 50px;
  margin-top: 3px;
  padding: 15px;
  color: white;
  background-color: var(--main-accent);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.card-button:hover {
  background-color: var(--main-accent-hover);
}

.card-button:active {
  background-color: var(--main-accent-focused);
}

.center {
  text-align: center;
}

@media screen and (max-width: 780px) {
  :root {
    --card-width: 31%;
    --button-width: 100%;
  }

  .card img {
    width: 100%;
  }
}

@media screen and (max-width: 550px) {
  :root {
    --card-width: 47%;
    --button-width: 100%;
  }
}
