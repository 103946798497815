::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-thumb {
  background: var(--main-accent);
}

::-webkit-scrollbar-thumb:hover {
  background: var(--main-accent-hover);
}

::-webkit-scrollbar-track {
  background: var(--main-accent-not-focused);
}

.parent-container {
  overflow-y: scroll;
}

.title-container {
  display: block;
  background-color: var(--component-bg-main);
  padding: 10px;
}

.card-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: 5px;
  background-color: var(--component-bg-main);
}
