:root {
  --component-bg-main: rgb(246, 246, 246);
  --component-bg-secondary: rgb(0, 145, 211);
  --component-element-bg: white;
  --main-accent: rgb(0, 145, 211);
  --main-accent-hover: rgba(0, 100, 180, 1);
  --main-accent-focused: rgba(0, 100, 180, 0.8);
  --main-accent-not-focused: rgba(103, 135, 183, 0.4);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
