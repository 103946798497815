.action-button {
  cursor: pointer;
  color: white;
  background-color: rgba(0, 120, 231, 0.8);
  padding: 10px;
  margin-right: 10px;
  border-radius: 8px;
}

.action-button:hover {
  background-color: rgba(0, 120, 231, 1);
}

.action-button:active {
  background-color: rgba(0, 120, 231, 0.9);
}
